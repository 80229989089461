

.App {
  text-align: center;
  margin-top: 0px;
  width: 100%;
  height: 100%;
  font-family: 'Quicksand', sans-serif;
  line-height: 1.5;
  word-spacing: 4px;
  letter-spacing: 1px;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.parallax_section {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: 100% auto;
  position: relative;
  background-image: url('./img/landing_background.jpg');
}

.center{
    margin: 0 auto;
}

.section_header{
  font-size: x-large;
  font-weight: bolder;
}

 .content_block{
  background-image: linear-gradient( #dfdbdb, white );
  border-radius: 0.5rem;
} 


/* Turn off parallax scrolling for tablets and phones. Increase the pixels if needed */
@media only screen and (max-device-width: 1366px) {
.parallax {
  background-attachment: scroll;
}
} 